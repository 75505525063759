///////////// User Profile /////////////

.user-profile-wrapper {
  margin-top: 4rem;
  max-height: 90vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .header-title {
    font-size: 1.5rem;
    color: #3f3f3f;
  }
  button {
    background-color: #f36b7f;
  }
  span {
    vertical-align: -webkit-baseline-middle;
    margin-left: 1rem;
    color: #3f3f3f;
  }
}

//************** Whole application checkbox css ********************

input[type='checkbox'] {
  appearance: none;
}

input[type='checkbox']::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 8px;
  border: 1px solid #bbbbbb;
}

input[type='checkbox']:checked::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 8px;
  border: 1px solid #bbbbbb;
  background-color: white;
  background-image: url(https://homh-site-media.s3.amazonaws.com/custom-uploads/tick.svg);
  background-repeat: no-repeat;
  background-size: 1rem 1.4rem;
  background-position: center;
}
