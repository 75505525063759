.login-modal {
  max-width: 25.313rem;
  width: auto;
  min-height: 40.375rem;
  height: auto;
  border-radius: 10px;
  background-color: #ffffff;
  transform: translateY(10%);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.login-wrapper {
  margin-bottom: 4rem;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .login-title {
    padding-top: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #3f3f3f;
  }
  button {
    background-color: #f36b7f;
    display: block;
  }
  .link-forgot {
    color: #34b2c3;
    text-align: end;
    font-size: 1rem;
    cursor: pointer;
  }
  .btn-create {
    width: 10rem;
    border-radius: 40px;
    border: solid 1px #f36b7f;
    background-color: white;
    color: #f36b7f;
  }
  .login-error {
    font-size: 1rem;
    text-align: center;
    color: #bf2200;
    padding-bottom: 0.751rem;
  }
  .login-loader {
    position: relative !important;
    top: 0.188rem !important;
  }
}

.forgot_heading {
  font-size: 1.438rem !important;
}

.reset-password-wrapper {
  width: 19.4rem;
  height: 50px;
  margin: 0 auto;
}
