.live-wrapper {
  max-height: 100vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.text-color {
  color: #3f3f3f !important;
}
.liveStream-details {
  margin: 0 !important;
}
.header-font-size {
  font-size: 1.25rem !important;
}
.border-height {
  border-top: 0.063rem solid #f3f3f3;
}
.need-to-know-question {
  font-size: 1.063rem !important;
  font-weight: 500 !important;
  color: #3f3f3f !important;
}
.questionBox {
  height: auto;
  border-radius: 5px;
  background-color: #dbf2f2;
  padding-bottom: 2rem;
}
.bottom-space {
  margin-bottom: 1.375rem !important;
}
.unrsvp-text {
  font-size: 1.063rem !important;
  font-weight: 500;
  text-align: left;
  color: #a9a9a9;
  margin-left: 0.563rem;
  cursor: pointer;
  text-decoration: underline;
}
.event-feedback {
  float: right;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #3f3f3f !important;
}
.video-player-mobile {
  height: 10.25rem !important;
  .livestream-iframe {
    height: 10.25rem !important;
  }
}
.livetstream-error {
  width: auto !important;
  top: 0;
  left: 0;
  z-index: 1;
  position: relative !important;
  height: 30rem !important;
  div {
    margin: 0 auto !important;
    position: relative;
    top: 10rem;
  }
}
