.signup-modal {
  max-width: 25.313rem;
  width: auto;
  min-height: 40.375rem;
  height: auto;
  border-radius: 0.625rem;
  background-color: #ffffff;
  transform: translateY(10%);
  padding: 2.5rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.signup-wrapper {
  .signup-title {
    padding-top: 2rem;
    font-size: 1.5rem !important;
    font-weight: bold;
    color: #3f3f3f;
  }

  .btn-next {
    justify-content: center;
    background-color: #f36b7f;
  }
  span {
    vertical-align: -webkit-baseline-middle;
    margin-left: 1rem;
    color: #3f3f3f;
  }
  .select_field__menu {
    border: 1px solid #d3d4d4;
  }
}

//************** Whole application checkbox css ********************

input[type='checkbox'] {
  width: 1.5rem;
  height: 1.5rem;
}

input[type='checkbox']::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.15rem;
  border: 1px solid #e3e3e3;
}

input[type='checkbox']:checked::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.116rem;
  border: 1px solid #e3e3e3;
  background-color: white;
  background-image: url(https://homh-site-media.s3.amazonaws.com/custom-uploads/tick.svg);
  background-repeat: no-repeat;
  background-size: 1rem 1.4rem;
  background-position: center;
}

///////////////////// Term and Conditions //////////////////
.term-modal {
  width: 20.438rem;
  padding: 2rem;

  .term-label {
    width: 15.125rem;
  }
  .term-check {
    padding-left: 1rem;
    color: #3f3f3f;
  }
  .btn-continue {
    background-color: #f36b7f;
  }
}
