.listing-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.event-card {
  margin: 0 auto;
}
.no-event-wrapper {
  .tab-btn-login {
    margin: 0 auto;
    float: none !important;
    width: 8.063rem !important;
    height: 3.375rem !important;
    background-color: #f36b7f;
    display: block !important;
    border-radius: 2rem !important;
  }
}
.card-wrapper {
  .btn-loadmore {
    margin: 0 auto;
    float: none !important;
    margin-bottom: 2rem;
    background-color: #f36b7f;
  }
}

.button_disable {
  cursor: not-allowed;
  opacity: 0.8;
  &:disabled {
    background-color: #d2d2d2;
  }
}
.position-relative {
  position: relative !important;
}

/////////////////// Event Detail Popup /////////////////
.description-wrapper {
  overflow-y: scroll;
  padding-bottom: 6rem;

  &::-webkit-scrollbar {
    display: none;
  }

  .event-title {
    font-size: 1.563rem;
    color: #3f3f3f;
  }
  .after-rsvp-title {
    font-size: 1.063rem;
    font-weight: bold;
    color: #3f3f3f;
  }
  .about-event-title {
    font-size: 1.25rem;
    color: #3f3f3f;
  }
  .about-sub-header {
    font-size: 1rem;
    color: #3f3f3f;
  }

  .first-child {
    height: auto;
    margin: 0 3rem;
  }

  .event-description {
    border-bottom: 0.063rem solid #f3f3f3;
    margin: 0 3rem;
    padding: 3rem 0;
    img {
      flex-grow: 0;
      border-radius: 3.938rem;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .QuestionBox-description {
    @extend .event-description;
    padding: 1rem 0;
  }

  .event-info {
    padding: 1rem 0;
    span {
      color: #5d5d5d;
    }
  }
  .btn-iwantrsvp {
    float: left !important;
    background-color: #f36b7f;
  }
  .btn-unrsvp {
    padding: 2rem 1rem;
    background-color: transparent;
    color: #a9a9a9;
    text-decoration: underline;
  }
  .btn-view-event {
    padding: 1rem 0;
    margin: 1rem 3rem;
  }
  .after-rsvp-waitlist {
    font-size: 1.063rem;
    font-weight: bold;
    color: #c02727;
  }
}

/////////////////// Event Rating //////////////////
.rating-textarea {
  height: 14rem !important;
}
.btn-disable {
  pointer-events: none;
  background-color: #c2c2c2 !important;
}

///////////////// Calendar //////////////////
.date-selected {
  background: #f36b7f !important;
  color: #ffffff !important;
  .event-dot {
    background-color: #ffffff !important;
  }
}
.date-hover {
  &:hover {
    @extend .date-selected;
  }
}

////////////////// Un-rsvp modal //////////////
.unrsvp-wrapper {
  .btn-no {
    background-color: #f36b7f;
  }
  .close-btn-icon {
    top: 2rem;
    right: 2rem;
  }
}

///////////////// Tab Class //////////////////
.tab-icon {
  width: 3rem !important;
  height: 3rem;
  position: relative;
  bottom: 0.5rem;
  padding-top: 0 !important;
}

////////////////// Cluster Selection //////////////////
.cluster-wrapper {
  margin: 0 auto;
  button {
    background-color: #f36b7f;
  }
}

///////////////// No Access ////////////////
.no-access {
  max-width: 33.438rem;
  min-height: 14.938rem;
  img {
    margin: 0 auto;
  }
  .title {
    font-size: 1.375rem;
    font-weight: 500;
  }
  .sub-title {
    font-size: 1.063rem;
    font-weight: normal;
  }
  .margin-auto {
    margin: 0 auto;
  }
}
