.contact-section {
  .contact-title {
    font-size: 1.563rem;
    font-weight: bold;
    color: #3f3f3f;
  }
  .contact-textarea {
    height: 14.063rem;
  }
  .btn-send {
    float: left;
    background-color: #f36b7f;
  }
}
