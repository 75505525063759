.report-modal {
  max-width: 50rem;
  width: auto;
  min-height: 15.875rem;
  height: auto;
  border-radius: 10px;
  background-color: #ffffff;
  transform: translateY(10%);
}

.report-wrapper {
  .report-title {
    font-size: 1.375rem;
    color: #3f3f3f;
  }
  .report-month {
    width: 16.25rem;
  }
  .btn-download {
    border-radius: 40px;
    border: solid 1px #f36b7f;
    background-color: #f36b7f;
    color: white;
    margin-top: 0.6rem;
  }
}
